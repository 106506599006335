#about-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 5rem 2rem;
    position: relative;
    overflow: hidden;
}

/* Efecto de luz de fondo */
#about-page::before {
    content: '';
    position: absolute;
    top: 20%;
    left: 10%;
    width: 40%;
    height: 40%;
    background: radial-gradient(circle, rgba(var(--primary-color-rgb), 0.15), transparent 70%);
    filter: blur(50px);
    z-index: 0;
    animation: floatLight 10s ease-in-out infinite alternate;
}

@keyframes floatLight {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(5%, 5%);
    }
}

#about-page > * {
    position: relative;
    z-index: 1;
}

#about-page .content {
    width: 55%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#about-page .content h2 {
    margin-bottom: 2rem;
    color: var(--light-accent);
    text-shadow: 0 0 15px rgba(var(--light-accent-rgb), 0.7);
    font-size: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 30px;
    border-radius: var(--card-border-radius);
    position: relative;
    overflow: hidden;
}

#about-page .content h2::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 5px;
    height: 100%;
    background: var(--light-accent);
    box-shadow: 0 0 20px rgba(var(--light-accent-rgb), 0.8);
}

#about-page .content p {
    font-size: 1rem;
    line-height: 1.8;
    color: var(--text-secondary);
    text-align: justify;
    padding: 2.5rem;
    border-radius: var(--card-border-radius);
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    border: var(--glass-border);
    box-shadow: var(--glass-shadow);
    position: relative;
    overflow: hidden;
}

#about-page .content p strong {
    color: var(--primary-color);
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
}

#about-page .content p strong::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, var(--primary-color), transparent);
}

#about-page .content p::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to bottom, var(--primary-color), transparent);
}

.avatar-container {
    width: 35%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    position: relative;
    animation: fadeInRight 1s ease-out;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.face-me {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-image: url('/public/face-me.png');
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 30px rgba(var(--primary-color-rgb), 0.5);
    position: relative;
    transition: transform 0.5s ease;
    border: 4px solid rgba(var(--primary-color-rgb), 0.2);
}

.face-me:hover {
    transform: scale(1.05);
    box-shadow: 0 0 40px rgba(var(--primary-color-rgb), 0.7);
}

/* Efecto de anillo futurista */
.face-me::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 50%;
    border: 2px solid rgba(var(--primary-color-rgb), 0.3);
    animation: pulseRing 2s linear infinite;
}

@keyframes pulseRing {
    0% {
        transform: scale(1);
        opacity: 0.3;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        transform: scale(1.1);
        opacity: 0;
    }
}

.hobbies-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    padding: 2rem;
    border-radius: var(--card-border-radius);
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    border: var(--glass-border);
    box-shadow: var(--glass-shadow);
}

.hobbies-container .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 5px rgba(var(--primary-color-rgb), 0.5));
}

.hobbies-container .icon:hover {
    transform: translateY(-8px) scale(1.2);
    color: var(--light-accent);
    filter: drop-shadow(0 0 8px rgba(var(--light-accent-rgb), 0.7));
}

@media (max-width: 1200px) {
    #about-page {
        gap: 3rem;
        padding: 3rem 1.5rem;
    }
    
    #about-page .content {
        width: 90%;
    }
    
    .avatar-container {
        width: 90%;
    }
}

@media (max-width: 768px) {
    #about-page {
        gap: 2rem;
        padding: 2rem 1rem;
    }
    
    #about-page .content p {
        padding: 1.5rem;
    }
    
    .face-me {
        width: 200px;
        height: 200px;
    }
}