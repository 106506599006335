:root {
    --skill-bar-lenght: 0%;
}

#skills-page {
    width: 100%;
    min-height: 100vh;
    color: var(--text-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 5rem 2rem;
    position: relative;
    overflow: hidden;
}

/* Efecto de luces hexagonales */
#skills-page::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 60%;
    background-image: url('/public/circuit-pattern.png');
    opacity: 0.05;
    z-index: 0;
    mask-image: radial-gradient(circle at 70% 30%, black 0%, transparent 70%);
    -webkit-mask-image: radial-gradient(circle at 70% 30%, black 0%, transparent 70%);
}

#skills-page > * {
    position: relative;
    z-index: 1;
}

#skills-page h2 {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
    color: var(--light-accent);
    text-shadow: 0 0 15px rgba(var(--light-accent-rgb), 0.7);
    font-size: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 30px;
    border-radius: var(--card-border-radius);
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

#skills-page h2::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 5px;
    height: 100%;
    background: var(--light-accent);
    box-shadow: 0 0 20px rgba(var(--light-accent-rgb), 0.8);
}

#skills-page h3 {
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    color: var(--primary-color);
    text-align: center;
    position: relative;
    display: inline-block;
}

#skills-page h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
}

.skills-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

#skills-page .tecnologies-container,
#skills-page .soft-skills {
    width: 45%;
    min-width: 400px;
    max-width: 550px;
    padding: 3rem;
    border-radius: var(--card-border-radius);
    background: var(--glass-bg);
    backdrop-filter: blur(10px);
    border: var(--glass-border);
    box-shadow: var(--glass-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    transition: all 0.4s ease;
    animation: fadeIn 1s ease-out;
    position: relative;
    overflow: hidden;
}

/* Efecto de brillo en esquina */
#skills-page .tecnologies-container::after,
#skills-page .soft-skills::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, rgba(var(--primary-color-rgb), 0.2), transparent 70%);
    opacity: 0.5;
    transition: all 0.5s ease;
}

#skills-page .tecnologies-container:hover::after,
#skills-page .soft-skills:hover::after {
    transform: scale(1.5);
    opacity: 0.8;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#skills-page .tecnologies-container:hover,
#skills-page .soft-skills:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
}

#skills-page .tecnologies-container {
    position: relative;
    overflow: hidden;
}

#skills-page .tecnologies-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, var(--primary-color), var(--light-accent));
}

.skills__item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
    position: relative;
}

.skills__item:last-child {
    margin-bottom: 0;
}

.skills__item .technologies-icon {
    font-size: 2.8rem;
    color: var(--primary-color);
    filter: drop-shadow(0 0 8px rgba(var(--primary-color-rgb), 0.5));
    transition: all 0.3s ease;
}

.skills__item:hover .technologies-icon {
    transform: scale(1.2) rotate(-5deg);
    color: var(--light-accent);
    filter: drop-shadow(0 0 12px rgba(var(--light-accent-rgb), 0.7));
}

.skills__bar,
.skills__bar::after {
    position: relative;
    width: 300px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 30px;
    overflow: hidden;
}

.skills__bar::after {
    content: '';
    position: absolute;
    left: -300px;
    border-radius: 30px;
    animation: skills-bar-fill 2.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
    top: 0;
    height: 100%;
}

/* Reiniciar la animación cuando sea necesario */
.skills__bar.animate::after {
    animation: none;
    animation: skills-bar-fill 2.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
}

/* Colores mejorados para las barras de habilidades */
.blue::after {
    background: linear-gradient(90deg, var(--primary-color), #3498db);
    box-shadow: 0 0 20px rgba(52, 152, 219, 0.5);
}

.red::after {
    background: linear-gradient(90deg, var(--primary-color), #e74c3c);
    box-shadow: 0 0 20px rgba(231, 76, 60, 0.5);
}

.orange::after {
    background: linear-gradient(90deg, var(--primary-color), #f39c12);
    box-shadow: 0 0 20px rgba(243, 156, 18, 0.5);
}

.violet::after {
    background: linear-gradient(90deg, var(--primary-color), #9b59b6);
    box-shadow: 0 0 20px rgba(155, 89, 182, 0.5);
}

.green::after {
    background: linear-gradient(90deg, var(--primary-color), #2ecc71);
    box-shadow: 0 0 20px rgba(46, 204, 113, 0.5);
}

.php-color::after {
    background: linear-gradient(90deg, var(--primary-color), #8e44ad);
    box-shadow: 0 0 20px rgba(142, 68, 173, 0.5);
}

.python-color::after {
    background: linear-gradient(90deg, var(--primary-color), #f1c40f);
    box-shadow: 0 0 20px rgba(241, 196, 15, 0.5);
}

/* Asegurar que las barras de progreso son visibles */
.skills__bar::after {
    width: var(--skill-bar-lenght);
    transform-origin: left;
}

/* Skill bar percentages - Valores claramente definidos */
.skills__bar--10::after { --skill-bar-lenght: 10%; }
.skills__bar--20::after { --skill-bar-lenght: 20%; }
.skills__bar--30::after { --skill-bar-lenght: 30%; }
.skills__bar--40::after { --skill-bar-lenght: 40%; }
.skills__bar--50::after { --skill-bar-lenght: 50%; }
.skills__bar--60::after { --skill-bar-lenght: 60%; }
.skills__bar--70::after { --skill-bar-lenght: 70%; }
.skills__bar--80::after { --skill-bar-lenght: 80%; }
.skills__bar--85::after { --skill-bar-lenght: 85%; }
.skills__bar--90::after { --skill-bar-lenght: 90%; }

@keyframes skills-bar-fill {
    0% {
        width: 0;
        left: 0;
    }
    100% {
        width: var(--skill-bar-lenght);
        left: 0;
    }
}

/* Soft Skills */
#skills-page .soft-skills {
    position: relative;
    overflow: hidden;
}

#skills-page .soft-skills::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to bottom, var(--primary-color), var(--light-accent));
}

.soft-skills-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
}

.soft-sklls-contain {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.soft-sklls-contain:hover {
    transform: translateY(-5px);
    background: rgba(var(--primary-color-rgb), 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.soft-sklls-contain span {
    width: 12px;
    height: 12px;
    margin-right: 15px;
    background: linear-gradient(45deg, var(--primary-color), var(--light-accent));
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(var(--primary-color-rgb), 0.5);
}

.soft-sklls-contain p {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
}

/* Responsividad */
@media (max-width: 960px) {
    #skills-page .tecnologies-container,
    #skills-page .soft-skills {
        width: 90%;
        min-width: 0;
        padding: 2rem;
    }
    
    .skills__bar,
    .skills__bar::after {
        width: 200px;
    }
    
    .skills__bar::after {
        left: -200px;
    }
    
    .skills__item {
        gap: 1rem;
    }
    
    .soft-skills-grid {
        grid-template-columns: 1fr;
    }
}