.cv-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  animation: pulse 2s infinite alternate ease-in-out;
}

.cv-container a {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(45deg, var(--primary-color), var(--light-accent));
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 30px;
  font-family: 'Trispace', sans-serif;
  transition: var(--hover-transition);
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(var(--primary-color-rgb), 0.4);
}

.cv-container a:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 8px 25px rgba(var(--primary-color-rgb), 0.6);
}

.cv-container a::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}

.cv-container a:hover::before {
  left: 100%;
}

/* Brillo indicador */
.cv-container::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  border-radius: 50%;
  top: 5px;
  right: 5px;
  box-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--primary-color);
  animation: blink 1.5s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  .cv-container {
    top: auto;
    bottom: 20px;
    right: 20px;
  }
  
  .cv-container a {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}