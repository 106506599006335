@import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';

#contact-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 5rem 2rem;
    position: relative;
    overflow: hidden;
}

/* Efecto de partículas flotantes */
#contact-page::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(circle at 80% 20%, rgba(var(--light-accent-rgb), 0.1), transparent 30%),
                radial-gradient(circle at 20% 80%, rgba(var(--primary-color-rgb), 0.15), transparent 40%);
    filter: blur(50px);
    z-index: -1;
}

#contact-page > * {
    position: relative;
    z-index: 1;
}

#contact-page .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    min-width: 400px;
    max-width: 600px;
    animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#contact-page .form-container h2 {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
}

#contact-page form {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: var(--card-border-radius);
    background: var(--glass-bg);
    backdrop-filter: blur(15px);
    border: var(--glass-border);
    box-shadow: var(--glass-shadow);
    position: relative;
    overflow: hidden;
}

/* Líneas decorativas futuristas */
#contact-page form::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
        linear-gradient(90deg, transparent 0%, transparent 48%, rgba(var(--primary-color-rgb), 0.1) 49%, rgba(var(--primary-color-rgb), 0.1) 51%, transparent 52%, transparent 100%),
        linear-gradient(0deg, transparent 0%, transparent 48%, rgba(var(--primary-color-rgb), 0.1) 49%, rgba(var(--primary-color-rgb), 0.1) 51%, transparent 52%, transparent 100%);
    background-size: 50px 50px;
    opacity: 0.5;
    z-index: -1;
}

#contact-page form label {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    font-weight: 600;
    color: var(--text-color);
    position: relative;
}

#contact-page form input[type="text"],
#contact-page form input[type="email"],
#contact-page form textarea {
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: var(--text-color);
    font-family: 'Trispace', sans-serif;
    resize: none;
    transition: all 0.3s ease;
}

#contact-page form input[type="text"]:focus,
#contact-page form input[type="email"]:focus,
#contact-page form textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 10px rgba(var(--primary-color-rgb), 0.3);
    background: rgba(255, 255, 255, 0.1);
}

#contact-page form textarea {
    min-height: 150px;
}

#contact-page .error-text,
#contact-page .error-text-message {
    color: var(--error-color);
    font-size: 0.8rem;
    margin-top: 0.3rem;
    font-weight: 400;
}

#contact-page .message-ok {
    color: var(--light-accent);
    text-align: center;
    font-weight: 600;
    transition: all 0.3s ease;
    animation: fadeInMessage 0.5s ease-out;
}

@keyframes fadeInMessage {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#contact-page .contact-button {
    padding: 0.8rem 1.5rem;
    background: linear-gradient(45deg, var(--primary-color), var(--light-accent));
    border: none;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    font-family: 'Trispace', sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.4s ease;
    align-self: center;
    margin-top: 1rem;
    box-shadow: 0 4px 15px rgba(var(--primary-color-rgb), 0.3);
    position: relative;
    overflow: hidden;
}

#contact-page .contact-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(var(--primary-color-rgb), 0.5);
}

#contact-page .contact-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
    );
    transition: 0.5s;
}

#contact-page .contact-button:hover::before {
    left: 100%;
}

/* Personalizar el mapa */
.leaflet-container {
    width: 45%;
    height: 500px;
    border-radius: var(--card-border-radius);
    overflow: hidden;
    border: var(--glass-border);
    box-shadow: var(--glass-shadow);
    animation: fadeIn 1s ease-out 0.3s backwards;
    position: relative;
    filter: saturate(0.7) contrast(1.2);
}

/* Estilo para el mapa oscuro */
.map-dark {
    filter: saturate(0.8) contrast(1.2) brightness(0.85);
}

/* Añadir borde de luz al mapa */
.leaflet-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, var(--primary-color), var(--light-accent));
    z-index: 1000;
}

/* Personalizar los controles y popups del mapa */
.leaflet-control-zoom {
    border: none !important;
    background: rgba(10, 10, 12, 0.8) !important;
    border-radius: 10px !important;
    margin: 15px !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    background-color: rgba(22, 160, 133, 0.2) !important;
    color: var(--primary-color) !important;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3) !important;
    transition: all 0.3s ease !important;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
    background-color: rgba(22, 160, 133, 0.5) !important;
    color: white !important;
}

.leaflet-popup-content-wrapper {
    background: rgba(15, 15, 20, 0.9) !important;
    color: var(--text-color) !important;
    border-radius: 10px !important;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3) !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
}

.leaflet-popup-tip {
    background: rgba(15, 15, 20, 0.9) !important;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3) !important;
}

/* Responsividad */
@media (max-width: 960px) {
    #contact-page {
        flex-direction: column;
        gap: 3rem;
        padding: 3rem 1rem;
    }
    
    #contact-page .form-container,
    .leaflet-container {
        width: 100%;
        min-width: 0;
    }
    
    .leaflet-container {
        height: 400px;
    }
}