* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  font-family: 'Trispace', sans-serif;
  flex-direction: column;
}

h2 {
  color: var(--primary-color);
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 20px;
}

h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
}