#presentation-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    position: relative;
    overflow: hidden;
}

/* Fondo animado con gradientes */
#presentation-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
        radial-gradient(circle at 20% 35%, rgba(var(--primary-color-rgb), 0.15), transparent 25%),
        radial-gradient(circle at 80% 10%, rgba(var(--light-accent-rgb), 0.1), transparent 35%);
    z-index: -1;
    animation: pulse 15s ease-in-out infinite alternate;
}

@keyframes pulse {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }
    100% {
        opacity: 0.8;
        transform: scale(1.1);
    }
}

/* Líneas de cuadrícula futurista */
#presentation-page::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
        linear-gradient(rgba(var(--primary-color-rgb), 0.1) 1px, transparent 1px),
        linear-gradient(90deg, rgba(var(--primary-color-rgb), 0.1) 1px, transparent 1px);
    background-size: 40px 40px;
    z-index: -1;
    opacity: 0.2;
    perspective: 1000px;
    transform-style: preserve-3d;
    transform: rotateX(60deg) scale(1.5);
    animation: grid-move 20s linear infinite;
}

@keyframes grid-move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 40px;
    }
}

.message-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--text-color);
    font-size: 3.5rem;
    text-align: center;
    transform: translateY(-50px);
    z-index: 1;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.message-container span {
    background: linear-gradient(90deg, var(--primary-color), var(--light-accent));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    display: inline-block;
    position: relative;
}

.message-container span::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background: linear-gradient(90deg, var(--primary-color), var(--light-accent));
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease;
}

.message-container:hover span::after {
    transform: scaleX(1);
}

#presentation-page p {
    font-size: 1.5rem;
    color: var(--text-secondary);
    font-weight: 300;
    letter-spacing: 3px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out 3s forwards;
    transform: translateY(20px);
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.contact-button-landing {
    cursor: pointer;
    padding: 0.8rem 1.8rem;
    background: linear-gradient(45deg, var(--primary-color), var(--light-accent));
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(var(--primary-color-rgb), 0.4);
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
    animation: buttonAppear 1s ease-in-out 4s forwards;
    opacity: 0;
    transform: translateY(20px);
    border: none;
    z-index: 1;
}

@keyframes buttonAppear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.contact-button-landing:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(var(--primary-color-rgb), 0.6);
}

.contact-button-landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
    );
    transition: 0.5s;
    z-index: -1;
}

.contact-button-landing:hover::before {
    left: 100%;
}

.contact-button-header {
    display: block;
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 3rem;
    z-index: 10;
    background: linear-gradient(45deg, var(--primary-color), var(--light-accent));
    padding: 0.6rem 1.2rem;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    box-shadow: 0 5px 15px rgba(var(--primary-color-rgb), 0.4);
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.contact-button-header:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(var(--primary-color-rgb), 0.6);
}

@media (max-width: 768px) {
    .message-container {
        font-size: 2.5rem;
    }
    
    #presentation-page p {
        font-size: 1.2rem;
    }
    
    .contact-button-header {
        top: 1rem;
        right: 1rem;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
}